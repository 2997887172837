//===========================================
// -Helpers Start
//===========================================

@import "helpers/variables";
@import "helpers/mixins";

//===========================================
// -Helpers End
//===========================================

//===========================================
// -Vendors Start
//===========================================

// Add packages as per your requirement
// package can be inlude via node_modules or manuallay from vendors folder

// or
@import "vendors/primeng";

//===========================================
// -Vendors End
//===========================================

//===========================================
// -Partials Start
//===========================================

@import "./partials/classes";
@import "./partials/global";

//===========================================
// -Partials End
//===========================================

//===========================================
// -components Start
//===========================================


//===========================================
// -components End
//===========================================

//===========================================
// -Pages Start
//===========================================

//===========================================
// -Pages End
//===========================================

.freshgreen{
    color: var(--freshgreen-background) !important;
}

.red{
    color: var(--red) !important;
}

.pillActive {
    .p-tag{
    background-color: var(--pillActivate) !important;
    }
}

.pillInactive {
    .p-tag{
        background-color: var(--pillDeactivate) !important;
    }
}

.background-red {
    background-color: var(--red) !important ;
}

.cursor-default{
    .p-rating{
        .p-rating-item{
            .p-element{
                .p-icon{
                    cursor: default;
                }
            }
        }
    }
}

.light-grey{
    color: #d0d0d0;
}

.vibrant-pink{
    color: #d65289;
}

.light-green{
    color: #6ce9a6;
}

.required {
    color: #b00020;
    font-size: 1.2em;
    margin-left: 2px;
}
  
.vibrant-pink-background{
    background-color: #d65289;
}

.bright-red{
    color: #9d2528;
}

.freshgreen-background{
    background-color: var(--freshgreen-background);
}

.vivid-purple-bg{
    background-color: #5f48da;
}