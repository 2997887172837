//===========================================
// -Variables start
//===========================================

//Design System

//Primary - Purple

$deep-purple: (
  40: #F8F7FF,
  50: #ECE9FB,
  100: #D2C8F4,
  200: #B2A5ED,
  300: #9280E6,
  400: #7765E1,
  500: #5F48DA,
  600: #4F3CC4,
  700: #472CB3,
  800: #3F1EA4,
  900: #311B92,
);

$deep-purple-alpha: (
  100: #B388FF,
  200: #7C4DFF,
  400: #651FFF,
  700: #6200EA,
);


//Secondary - Pink

$pink: (
  50: #FCE4EC,
  100: #EDBDD5,
  200: #E495BA,
  300: #DC6D9F,
  400: #D65289,
  500: #D23D75,
  600: #C13970,
  700: #AB3768,
  800: #963361,
  900: #702A53,
);

$pink-alpha: (
  100: #F48FB1,
  200: #EC407A,
  400: #C2185B,
  700: #880E4F,
);

//Tertiary - Indigo
$indigo: (
  10: #F0F2FF,
  50:#E8EAF6,
  100:#C5CAE9,
  200:#9FA8DA,
  300:#7986CB,
  400:#5C6BC0,
  500:#3F51B5,
  600:#3949AB,
  700:#303F9F,
  800:#283593,
  900:#1A237E,
);

$indigo-alpha: (
  A100:#8C9EFF,
  A200:#536DFE,
  A400:#3D5AFE,
  A700:#304FFE,
);

$typography: (
  h1: 96px,
  h2: 60px,
  h3: 48px,
  h4: 34px,
  h5: 24px,
  h6: 20px,
  body1: 16px,
  body2: 14px,
  subtitle1: 16px,
  subtitle2: 14px,
  caption: 12px,
  overline: 12px,
);

// Colors start

// define your vars or override bootstrap vars

$primary: map-get($deep-purple, 500);
$primaryDark: map-get($deep-purple, 900);
$primary-light: #F0F2FF;

$secondary: map-get($pink, 400);

$deep-purple-200: map-get($deep-purple, 200);
$deep-purple-900: map-get($deep-purple, 900);

$white: #ffffff;
$black: #000000;
$danger: #B3261E;
$danger-light: #D32F2F;
$info: #0288D1;

// Typography Family
$font-default: "Roboto", sans-serif;
$font-primary: "Montserrat", sans-serif;

$font-path: "../fonts";

// define your Break Points or override bootstrap Break Points
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);



//Override PrimeNg
$primaryColor: $primary;
$fontFamily: $font-default;
$secondaryColor: $secondary;

//Switch
$inputSwitchWidth: 34px;
$inputSwitchHeight: 14px;
$inputSwitchHandleWidth: 20px;
$inputSwitchHandleHeight: 20px;
// $inputSwitchHandleOffBg: $primaryColor;


//Chips
$chipTextColor: map-get($deep-purple, 900);
$chipBorderRadius: 16px;


//Breadcrumb

// $breadcrumbItemTextColor: $menuitemTextColor;
// $breadcrumbItemIconColor: $menuitemIconColor;
// $breadcrumbBg: $menuBg;
// $breadcrumbPadding: 14px 0;
// $breadcrumbLastItemTextColor: $menuitemTextColor;
// $breadcrumbLastItemIconColor: $menuitemIconColor;
$breadcrumbBorder: none;

//Input
$inputBorder: 1px solid map-get($deep-purple, 200);
$inputIconColor: map-get($deep-purple, 200);
$borderRadius: 4px;

//Card
$cardTitleFontSize: 1.25rem;
$cardTitleFontWeight: 500;
$cardShadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.06), 0px 1px 10px 0px rgba(0, 0, 0, 0.06), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);


//Panel
$panelHeaderFontWeight: 500;

//TabView
$tabviewNavBorder: none;
// $tabviewHeaderSpacing: auto;
$tabviewHeaderBorderColor: transparent;
$tabviewHeaderHoverBorderColor: transparent;
$tabviewHeaderPadding: 1rem 3.5rem;


//paginator
$paginatorBg: transparent;
$paginatorBorder: transparent;
$paginatorElementHoverBg: #5F48DA;
$paginatorElementIconHoverColor: white;
$paginatorElementWidth: 2rem;
$paginatorElementHeight: 2rem;
$paginatorBorderWidth: 0;

//Table
$tableHeaderBg: map-get($indigo, 10);
$tableHeaderCellBg: map-get($indigo, 10);
$tableHeaderCellHoverBg: map-get($indigo, 10);
$tableHeaderCellHighlightBg: map-get($indigo, 10);
$tableHeaderCellHighlightHoverBg: map-get($indigo, 10);
$tableHeaderCellHighlightTextHoverColor: $primary;
$tableHeaderCellIconHoverColor: $primary;
$tableHeaderCellHighlightTextColor: $primary;
$tableHeaderPadding: 1rem 1rem;
$tableHeaderCellPadding: 1rem 1rem;

//Dialog
$dialogHeaderPadding: 1rem 1.5rem;
$dialogContentPadding: 2rem 3rem;
$dialogFooterPadding: 1rem 1.5rem;

$dialogHeaderActionIconWidth: 2.25rem;
$dialogHeaderActionIconHeight: 2.25rem;


//accordion
$accordionHeaderPadding: 8px 12px;
$accordionContentPadding: 22px;
$accordionContentBg: rgba(0, 0, 0, 0.04);
$accordionHeaderBorder: 1px solid rgba(0, 0, 0, 0.12);
$accordionHeaderHoverBorderColor: rgba(0, 0, 0, 0.12);
$accordionHeaderActiveBorderColor: rgba(0, 0, 0, 0.12);
$accordionSpacing: 0;
$accordionTableBackgroundColor: #f9fafb;
// $accordionHeaderFontWeight: 500;
// $accordionHeaderBg: #ffffff;
// $accordionHeaderTextColor: $textColor;
// $accordionHeaderHoverBg: #f6f6f6;
// $accordionHeaderTextHoverColor: $textColor;
// $accordionHeaderActiveBg: #ffffff;
// $accordionHeaderTextActiveColor: $textColor;
// $accordionHeaderActiveHoverBg: #ffffff;
// $accordionHeaderActiveHoverBorderColor: transparent;
// $accordionHeaderTextActiveHoverColor: $textColor;
// $accordionContentBorder: 0 none;
// $accordionContentTextColor: $textColor;

//TODO: Update this patch this is temporary fix

:root {
  --primary-40: #{map-get($deep-purple, 40)};
  --primary-50: #{map-get($deep-purple, 50)};
  --primary-100: #{map-get($deep-purple, 100)};
  --primary-200: #{map-get($deep-purple, 200)};
  --primary-300: #{map-get($deep-purple, 300)};
  --primary-400: #{map-get($deep-purple, 400)};
  --primary-500: #{map-get($deep-purple, 500)};
  --primary-600: #{map-get($deep-purple, 600)};
  --primary-700: #{map-get($deep-purple, 700)};
  --primary-800: #{map-get($deep-purple, 800)};
  --primary-900: #{map-get($deep-purple, 900)};

  --purple-40: #{map-get($deep-purple, 40)};
  --purple-50: #{map-get($deep-purple, 50)};
  --purple-100: #{map-get($deep-purple, 100)};
  --purple-200: #{map-get($deep-purple, 200)};
  --purple-300: #{map-get($deep-purple, 300)};
  --purple-400: #{map-get($deep-purple, 400)};
  --purple-500: #{map-get($deep-purple, 500)};
  --purple-600: #{map-get($deep-purple, 600)};
  --purple-700: #{map-get($deep-purple, 700)};
  --purple-800: #{map-get($deep-purple, 800)};
  --purple-900: #{map-get($deep-purple, 900)};

  --indigo-10: #{map-get($indigo,10)};
  --indigo-50: #{map-get($indigo,50)};
  --indigo-100: #{map-get($indigo,100)};
  --indigo-200: #{map-get($indigo,200)};
  --indigo-300: #{map-get($indigo,300)};
  --indigo-400: #{map-get($indigo,400)};
  --indigo-500: #{map-get($indigo,500)};
  --indigo-600: #{map-get($indigo,600)};
  --indigo-700: #{map-get($indigo,700)};
  --indigo-800: #{map-get($indigo,800)};
  --indigo-900: #{map-get($indigo,900)};

  --pink-50: #{map-get($pink, 50)};
  --pink-100: #{map-get($pink, 100)};
  --pink-200: #{map-get($pink, 200)};
  --pink-300: #{map-get($pink, 300)};
  --pink-400: #{map-get($pink, 400)};
  --pink-500: #{map-get($pink, 500)};
  --pink-600: #{map-get($pink, 600)};
  --pink-700: #{map-get($pink, 700)};
  --pink-800: #{map-get($pink, 800)};
  --pink-900: #{map-get($pink, 900)};

// ============== activate/deactivate pill colors

  --pillActivate: #19b569;
  --pillDeactivate: #d0d0d0;

// =======================================
  --teal: #12b7a6;
  --red: #f97066;
  --freshgreen-background: #19b569;
}

// ===========================================
// -Variables end
// ===========================================  